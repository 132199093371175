import React, { useState } from 'react'
import styled from 'styled-components'

import { Section, Text, Box } from '../components/Core'
import { Row, Col, Container } from 'react-bootstrap'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { device } from '../utils'
import { graphql } from 'gatsby'

const StyledSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .kind-words {
    font-family: 'Vijaya';

    font-size: 40px;
    line-height: 50px;
    padding-bottom: 20px;
    font-weight: 100;
    text-transform: uppercase;
  }

  .react-multi-carousel-list {
    padding-left: unset;
    padding-right: unset;
  }

  .swipe {
    display: none;
    padding-top: 25px;
    color: black;
    font-size: 15px;
    font-weight: 200;
  }

  @media ${device.sm} {
    .kind-words {
      font-size: 25px;
      line-height: 30px;
    }

    .swipe {
      display: inline-block;
    }
  }

  @media ${device.md} {
    .swipe {
      display: inline-block;
    }
  }

  @media ${device.lg} {
    .swipe {
      display: none;
    }
  }
`

const StyledBox = styled(Box)`
  .copy {
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
    letter-spacing: 0.25px;
  }

  .name {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.25px;

    font-weight: 200;

    padding-top: 50px;
  }

  @media ${device.sm} {
    padding: 30px;
    margin: 20px;
  }
  @media ${device.md} {
    padding: 50px;
    margin-left: 100px;
    margin-right: 100px;

  }

  @media ${device.lg} {
    padding: 50px;
    margin: 20px;
    min-height: 325px !important;

  }
`

const Testimonial = ({ copy, name }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Col>
        <StyledBox bg="white" className="position-relative" data-aos="zoom-in">
          <Text color="#707070" className="copy">
            {copy}
          </Text>
          <Text color="#707070" className="name">
            {name}
          </Text>
        </StyledBox>
      </Col>
    </>
  )
}

const Testimonials = ({slice}) => {
  const [count, setCount] = useState(1)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection bg="#F1F3F4" className="position-relative">
        <Container>
          <Text className="kind-words" color="#707070">
            {slice.primary.testimonials_title.text}
          </Text>
          <Row>
            <Carousel
              responsive={responsive}
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              afterChange={(nextSlide, { currentSlide, onMove }) => {
                setCount(currentSlide + 1)
              }}
            >
              {slice.items.map(item => {
                  return(<Testimonial
                    name={item.name.text}
                    copy={item.testimonial.text}
                  />) 
                })}

      
            </Carousel>

            <Text className="swipe">{count}/{slice.items.length} | Swipe for next</Text>
          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default Testimonials


export const query = graphql`
  fragment HomepageDataBodyTestimonials on PrismicHomepageDataBodyTestimonials {
    primary {
      testimonials_title {
        text
      }
    }
    items {
      testimonial {
        text
      }
      name {
        text
      }
    }
  }`

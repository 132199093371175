import React from 'react'
import styled from 'styled-components'

import { Section, Text } from '../components/Core'
import { Row, Col, Container } from 'react-bootstrap'

import BlogCard from '../components/BlogCard'

import Line from '../components/Line'

import Masonry from 'react-masonry-css'
import { device } from '../utils'
import { Link } from 'gatsby'

import { useStaticQuery, graphql } from "gatsby"


const StyledSection = styled(Section)`
  text-align: center;
  padding-top: 0px;

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
  @media ${device.sm} {
    .view-all {
      font-size: 15px;
      line-height: 25px;
    }

    .section-title {
      font-size: 25px;
      line-height: 30px;
    }
  }


  @media ${device.md} {
    .inset {
      padding-left: 100px;
      padding-right: 100px;
    }

    /* Style your items */
    .my-masonry-grid_column > div {
      /* change div to reference your elements you put in <Masonry> */
      padding-right: 50px;
      padding-top: 50px;

      border-bottom: 1px solid black;
    }
    .my-masonry-grid_column:nth-child(2) .blog-card {
      padding-left: 50px;
      padding-right: 0px !important;
    }

    .my-masonry-grid_column > div:first-child {
      padding-top: 0px;
    }

    .my-masonry-grid_column:nth-child(1) {
      border-right: 1px solid black;
    }

    .my-masonry-grid_column > div:nth-last-child(1) {
      border-bottom: unset;
    }

    .my-masonry-grid_column > div:nth-first-child(1) {
      padding-top: 0px;
    }
    .title {
      font-size: 25px;
      line-height: 30px;
      padding-bottom: 20px;
      padding-top: 20px;
    }
    .view-all {
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 25px;
      line-height: 30px;
      padding: 10px;
    }
  }

  .section-title {
    font-family: 'Vijaya';

    font-size: 40px;
    line-height: 50px;
    font-weight: 100;
    text-transform: uppercase;
    padding-bottom: 15px;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
  }

  .view-all {
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 200;
    display: inline-block;
  }

  @media ${device.lg} {
    .inset {
      padding-left: 200px;
      padding-right: 200px;
    }
  }

  .none {
    display: none;
  }
`

const Blogs = ({ inset = 'false', recent = false, hide=false }) => {
  const breakpointColumnsObj = {
    default: 2,
    500: 2,
  }
  const data = useStaticQuery(graphql`
  query BlogQuery {
    allPrismicBlogPost {
      nodes {
        data {
          title {
            text
          }
          hero {
            url
          }
          post_date(formatString: "DD MMMM, YYYY")
        }
        uid
      }
    }
  }
      `)


  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection bg="white">
        <Container className={`flex-container ${inset && 'inset'}`}>
          <Line />
          <Text className="section-title" color="#707070">
            {recent ? 'Recent Updates' : 'Blog'}
          </Text>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
          {data.allPrismicBlogPost.nodes.map((item, index) => {
            return  <BlogCard title={item.data.title.text} image={`${item.data.hero.url}&w=500`} date={item.data.post_date} uid={item.uid}/>;
          })}
          </Masonry>
        </Container>
        {!hide &&
        <Link to="/blog" className={`hover ${recent ? '' : 'none'} d-inline-block`}>
          <Text className="title view-all " color="#707070">
            View All
          </Text>
        </Link>

        }

      </StyledSection>
    </>
  )
}

export default Blogs

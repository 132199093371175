import React from 'react'
import { Box } from '../Core'
import styled from 'styled-components'
import { device } from '../../utils'

const StyledLine = styled.span`
  display: block;
  width: 1px;
  background: black;
  margin: 10px auto 10px;
  @media ${device.sm} {
    height: 50px;
  }
  @media ${device.md} {
    height: 75px;
  }

  @media ${device.lg} {
    height: 100px;
  }
`

const Line = ({ color = 'black' }) => {
  return (
    <>
      <StyledLine style={{ background: color }} />
    </>
  )
}

export default Line

import React from 'react'
import { Box, Title } from '../Core'
import styled from 'styled-components'
import { Col } from 'react-bootstrap'
import { Text } from '../../components/Core'
import { device } from '../../utils'
import { Link } from 'gatsby'

const StyledCol = styled.div`
  text-align: center;

  .title {
    font-size: 15px;
    line-height: 20px;
    color: #707070;
    font-weight: 100;
    letter-spacing: 0.2px;
  }

  .date {
    margin-top: 0px;
    font-size: 10px;
    line-height: 15px;
    color: #7295a7;
    font-weight: 100;
    letter-spacing: 0.5px;
  }

  .blog-img {
    width: 100%;
  }

  @media ${device.sm} {
    margin: 5px;
  }

  @media ${device.lg} {
    .title {
      font-size: 25px;
      line-height: 30px;
    }

    .date {
      margin-top: 40px;
      font-size: 20px;
      line-height: 25px;
    }
    padding-bottom: 30px;
  }

  padding-bottom: 5px;
`

const BlogCard = ({ title, date, image, uid }) => {
  return (
    <>
      <StyledCol className="blog-card hover" data-aos="zoom-in">
        <Link to={`/blog/${uid}`} className='hover'>
          <div>
            <img src={image} className="blog-img"></img>
            <Text className="date pt-2">{date}</Text>
            <Text className="title pt-2">{title}</Text>
          </div>
        </Link>
      </StyledCol>
    </>
  )
}

export default BlogCard

import React, { useState } from 'react'
import styled from 'styled-components'

import { Section, Text, Box } from '../components/Core'
import { Row, Col, Container } from 'react-bootstrap'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { device } from '../utils'
import { Link } from "gatsby"


import { useStaticQuery, graphql } from "gatsby"

const StyledSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .kind-words {
    font-size: 40px;
    line-height: 50px;
    padding-bottom: 20px;
    font-weight: 200;
    text-transform: uppercase;
  }

  .learn-more {
    margin-top: 40px;
    font-size: 25px;
    line-height: 30px;
    font-weight: 200;
    text-transform: uppercase;
    padding: 10px;
    border: 1px solid #707070;
    display: inline-block;
  }
  .bg-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    opacity: 0.6;
  }
  .react-multi-carousel-list {
    padding-left: unset;
    padding-right: unset;
  }

  .mobile {
    display: none;
  }

  .swipe {
    display: none;
    font-weight: 200;
    font-size: 15px;
    line-height: 25px;
    padding-bottom: 25px;
  }

  @media ${device.sm} {
    .swipe {
      display: block;
    }

    .mobile {
      display: flex;
    }
    .desktop {
      display: none;
    }

    .kind-words {
      font-size: 30px;
      line-height: 35px;
    }
  }

  @media ${device.md} {
    .swipe {
      display: block;
    }
  }

  @media ${device.lg} {
    .swipe {
      display: none;
    }
  }
`

const StyledBox = styled(Box)`
  z-index: 5;
  padding: 10px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: -40px;

  .copy {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.25px;
  }

  .name {
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.25px;

    font-weight: 200;
  }

  .react-multi-carousel-list {
    padding-left: unset;
    padding-right: unset;
  }
`

const TitleBox = styled(Box)`
  display: inline-block;
  margin-top: -30px;
  padding: 10px;
  min-width: 25vw;
`

const StyledService = styled(Col)`
  :hover {
    cursor: pointer !important;
  }

  @media ${device.sm} {
    margin: 2rem;
  }

  @media ${device.md} {
    margin: 6rem;
  }

  @media ${device.lg} {
    margin: 1rem;
  }

  .box-img{
    height: 300px;
    object-fit: cover;
  }
`

const Service = ({ number, name, image, clickHandler }) => {


  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledService onClick={clickHandler} className='hover'>
        <img className="w-100 box-img" src={image}></img>
        <StyledBox bg="#F1F3F4" className="position-relative">
          <Text color="#707070" className="copy">
            {number}
          </Text>
          <Text color="#707070" className="name">
            {name}
          </Text>
        </StyledBox>
      </StyledService>
    </>
  )
}

const StyledCopy = styled(Section)`
  padding-top: 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  transition: 0.4s;
  display: flex;



  .bg-overlay {
    height: 20vh;
    object-fit: cover;
  }

  .opacity {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  .content {
    position: relative;

    z-index: 2;
  }
  .t-light {
    padding-top: 15px;
    font-weight: 200;
  }

  .arrow {
    height: 15px;
    position: unset;
  }

  .cta-container {
    padding-top: 20px;
    display: inline-flex;
    align-items: center;
  }

  .copy {
    font-size: 20px;
    line-height: 25px;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .title {
    font-family: 'Vijaya';

    font-weight: 200;
    font-size: 40px;
    line-height: 50px;
  }
  @media ${device.sm} {
    .copy {
      font-size: 20px;
      line-height: 25px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
`

const SubmitButton = styled.button`
  display: inline-block;
  margin-top: 1.5rem;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  font-size: 20px;
  line-height: 25px;
  font-weight: 200;
  text-transform: uppercase;
  border: 1px solid #707070;
  color: black;
`

const StyledImageBlockRow = styled(Row)`
padding-top: 20px;
padding-bottom: 20px;
align-items: center;
justify-content: center;

.index{
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.title-item{
  padding-left: 15px;
  padding-right: 15px;
}

@media ${device.md} {
    padding-left: 5rem;
    padding-right: 5rem;
}
`

const ImageBlock = ({ id, data }) => {

  const blocks = data[id].block

  return (
    <>
      <StyledImageBlockRow>
        {blocks.map((item, index) => {
          return (
            <Col md={3}>
              <img src={item.image.url} width="120px" height="120px"></img>
              <p className='index'>{index +1 }</p>

              <p className='title-item'>{item.title.text}</p>

            </Col>
          )
        })}

      </StyledImageBlockRow>
    </>
  )
}



const ColBlock = ({ id, data }) => {

  const blocks = data[id].cols

  return (
    <>
      <StyledImageBlockRow>
        {blocks.map((item, index) => {
          return (
            <Col md={3}>
              <p dangerouslySetInnerHTML={{ __html: item.col.html }}></p>
            </Col>
          )
        })}

      </StyledImageBlockRow>
    </>
  )
}

const CopyBlock = ({ image, title, copy, show, extra_section, image_blocks, text_blocks }) => {

  return (
    <>
      <StyledCopy
        className={`position-relative hide ${show ? 'show' : ''}`}
      >
        <img src={image} className="w-100 bg-overlay" />

        <Container className="content">
          <StyledBox bg="white">
            <Text variant="lg" color="#707070" className="title">
              {title}
            </Text>
          </StyledBox>
          <Text variant="small" color="#707070" className="mt-3 t-light copy" dangerouslySetInnerHTML={{ __html: copy }}>
          </Text>

       
          {extra_section.type === "text_cols" &&
            <ColBlock id={extra_section.id} data={text_blocks} />
          }

          {extra_section.type === "image_grid" &&
            <ImageBlock id={extra_section.id} data={image_blocks} />
          }



          <Link to="/contact">
            <SubmitButton className='hover' type="submit">Contact Us</SubmitButton>
          </Link>
        </Container>
      </StyledCopy>
    </>
  )
}

const Services = ({ slice }) => {
  const [currentCard, setCard] = useState(null)
  const [count, setCount] = useState(1)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const service_data = useStaticQuery(graphql`
  
  query ServiceData {
    allPrismicImageGrid(sort: {fields: prismicId}) {
      nodes {
        prismicId
        data {
          block {
            title {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
    allPrismicTextCols(sort: {fields: prismicId}) {
      nodes {
        prismicId
        data {
          cols {
            col {
              html
            }
          }
        }
      }
    }
  }
  
  `)

  var image_blocks = {}
  var text_blocks = {}
  for (var item in service_data.allPrismicImageGrid.nodes) {
    var id = service_data.allPrismicImageGrid.nodes[item].prismicId
    image_blocks[id] = service_data.allPrismicImageGrid.nodes[item].data
  }

  for (var item in service_data.allPrismicTextCols.nodes) {
    var id = service_data.allPrismicTextCols.nodes[item].prismicId
    text_blocks[id] = service_data.allPrismicTextCols.nodes[item].data
  }




  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection bg="#F1F3F4" className="position-relative">
        <Container data-aos="zoom-in">
          <Text className="kind-words" color="#707070">
            {slice.primary.text}
          </Text>
          <Row>
            <Carousel
              responsive={responsive}
              infinite={false}
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              afterChange={(nextSlide, { currentSlide, onMove }) => {
                setCount(currentSlide + 1)
              }}
            >
              {slice.items.map((item, index) => {
                return (
                  <Service
                    name={item.title.text}
                    number={`0${index + 1}.`}
                    image={item.image.url}
                    clickHandler={() => setCard(index)}
                  />
                )
              })}
            </Carousel>
          </Row>
          <Row>
            <Text className="swipe" color="black">
              {count}/{slice.items.length} | Swipe for next
            </Text>
          </Row>
        </Container>
      </StyledSection>

      {slice.items.map((item, index) => {
        return (
          <CopyBlock
            image={item.image.url}
            title={item.title.text}
            copy={item.text.html}
            extra_section={item.extra_section}
            image_blocks={image_blocks}
            text_blocks={text_blocks}
            show={currentCard == index ? true : false}
          />
        )

      })}

    </>
  )
}

export default Services

export const query = graphql`
  fragment PageDataBodyOurServicesPage on PrismicPageDataBodyOurServicesPage {
    items {
      title {
        text
      }
      text {
        html
      }
      extra_section {
        id
        type
      }
      image {
        alt
        copyright
        url
        gatsbyImageData
      }
    }
    primary {
      title {
        text
      }
    }
 }
`
import React, { useState } from 'react'
import styled from 'styled-components'

import { Section, Text } from '../components/Core'

import { Row, Col, Container } from 'react-bootstrap'

import Property from '../components/Property'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { device } from '../utils'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"

const StyledSection = styled(Section)`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .title {
    font-family: 'Vijaya';
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
    font-weight: 100;
  }

  .view-all {
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
    font-weight: 200;
    padding-bottom: 2rem;
  }

  .swipe {
    display: none;
  }

  .react-multi-carousel-list {
    position: unset !important;
  }

  .react-multi-carousel-list {
    padding-left: unset;
    padding-right: unset;
  }

  .react-multiple-carousel__arrow {
    background: none;
    top: 40%;
  }
  .react-multiple-carousel__arrow::before {
    color: #7295a7;
    font-size: 40px;
  }
  @media ${device.md} {
    .property-container {
      max-width: 85vw;
    }

    .property {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .swipe {
      display: block;
      font-weight: 200;
      font-size: 15px;
      line-height: 25px;
      padding-bottom: 25px;
    }
  }
  @media ${device.lg} {
    .title {
      font-size: 40px;
      line-height: 50px;
    }
    .property {
      margin: 40px;
    }

    .swipe {
      display: none;
    }
  }

  @media ${device.sm} {
    .view-all {
      font-size: 15px;
      line-height: 25px;
      padding-bottom: 25px;
      font-weight: 200;
    }
    .swipe {
      display: block;
      font-weight: 200;
      font-size: 15px;
      line-height: 25px;
      padding-bottom: 25px;
    }

    .property {
      margin: 20px;
    }
  }

 a:hover,
 a:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}
`

const Rentals = ({ slice, bg = 'light' }) => {
  const [count, setCount] = useState(1)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const data = useStaticQuery(graphql`
  {
    allListings(sort: {fields: id__normalized, order: DESC}, limit: 10) {
      nodes {
        price
        bathrooms
        bedrooms
        carports
        streetNo
        street
        suburb
        postcode
        mainImageUrl
        garages
      }
      totalCount
    }
    
  }
    `)


  

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection
        className="position-relative"
        bg="#F1F3F4"
        py={['0px', null, '0px', '25px']}
      >
        <Container fluid className="property-container">
          <Text className="title mb-4" color="#707070">
            {slice.primary.rental_title.text}
          </Text>
          <Row>
            <Carousel
              responsive={responsive}
              infinite={false}
              removeArrowOnDeviceType={['tablet', 'mobile']}
              afterChange={(nextSlide, { currentSlide, onMove }) => {
                setCount(currentSlide + 1)
              }}
            >
            {data.allListings.nodes.map((item, index) => {
              return (
                <Col className="property">
                <Property
                    price={item.price}
                    bathroom={item.bathrooms}
                    car={`${item.carports + item.garages}`}
                    bedroom={item.bedrooms}
                    address={`${item.streetNo} ${item.street}, ${item.suburb} ${item.postcode}`}
                    image={item.mainImageUrl}
                    unset={true}
                    streetNo={item.streetNo}
                    street={item.street}
                    suburb={item.suburb}
                    postcode={item.postcode}
                  />
                </Col>

              );
            })}

            </Carousel>
          </Row>
          <Row className="">
            <Text className="swipe" color="#707070">
              {count}/{data.allListings.totalCount} | Swipe for next
            </Text>
          </Row>

          <Row className="d-inline-flex">
            <Link to="/rentals" className='hover'>
              <Text className="view-all" color="#707070">
                View All
              </Text>
            </Link>
          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default Rentals


export const query = graphql`
  fragment HomepageDataBodyRentalProperties on PrismicHomepageDataBodyRentalProperties {
    primary {
      rental_title {
        text
      }
    }
  }
  
  
  `

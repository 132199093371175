import React from 'react'
import styled from 'styled-components'

import { Section, Title, Text } from '../components/Core'
import earBg from '../assets/ear/living_room.png'
import { Container, Row, Col } from 'react-bootstrap'
import { device } from '../utils'
import { Link } from "gatsby"
import { graphql } from 'gatsby'

const StyledSection = styled(Section)`
  min-width: 100vw;
  background-color: white;
  text-align: center;
  display: flex;
  align-items: center;

  .title {
    font-family: 'Vijaya';
    font-size: 40px;
    line-height: 50px;
    font-weight: 100;
    padding-bottom: 20px;
  }

  .text {
    padding-top: 20px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
    letter-spacing: 0.25px;
  }

  .learn-more {
    margin-top: 50px;
    line-height: 25px;
    padding: 10px 30px 10px 30px;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid #707070;
    display: inline-block;
    font-weight: 200;
  }

  @media ${device.sm} {
    .title {
      font-size: 25px;
      line-height: 30px;
    }

    .text {
      font-size: 20px;
      line-height: 25px;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .learn-more {
      font-size: 15px;
    }
  }
  @media ${device.md} {
    .inset {
      max-width: 70%;
    }

    .flex-center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const About = ({ slice, inset = null }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection>
        <Container className="position-relative flex-center" data-aos="zoom-in">
          <Text color="#707070" className="title">
            {slice.primary.rich_text_title.text}
          </Text>

          <Text color="#707070" className={`text ${inset && 'inset'}`}>
          {slice.primary.copy.text}
          </Text>
          <Link to='/about' className='hover'>
          <Text color="#707070" className="learn-more">
          {slice.primary.cta.text}
          </Text>
          
          </Link>
        </Container>
      </StyledSection>
    </>
  )
}

export default About

export const query = graphql`
  fragment HomepageDataBodyRichTextBasic on PrismicHomepageDataBodyRichTextBasic {
    primary {
      copy {
        text
      }
      cta {
        text
      }
      rich_text_title {
        text
      }
    }

  }`

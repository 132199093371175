import React from 'react'
import styled from 'styled-components'

import { Text } from '../../components/Core'
import earBg from '../../assets/ear/black.jpg'
import { Container } from 'react-bootstrap'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import houseOne from '../../assets/ear/house_1.png'
import houseTwo from '../../assets/ear/house_2.png'

import { device } from '../../utils'

import earLogoLight from '../../assets/ear/white_ear_logo.svg'

import arrowGlyph from '../../assets/ear/glyph/arrow-white.svg'

import PaintDrip from '../../components/PaintDrip'

import Img from 'gatsby-image'


const StyledSection = styled.div`
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
  background-color: black;

  .image-carousel img{
    opacity: 0.5;
  }

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  @media ${device.sm} {
    min-height: 100vh;
    background-position: center;
    background-size: cover;

    img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
  }

  @media ${device.md} {
    min-height: 60vh;
    background-position: center;
    background-size: cover;

    img {
      width: 100vw;
      height: 60vh;
      object-fit: cover;
    }
  }

  @media ${device.lg} {
    min-height: 100vh;
    background-position: center;
    background-size: cover;

    img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
  }

  .gatsby-image-wrapper{
    width: 100vw;
    height: 100vh
  }
`

const EarCTA = styled.div`

min-width: 100%;
min-height: 100%;

position: absolute;
z-index: 5;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}
.cta-container .hero-img{
  width: 100%;
  height: 60%;
  max-height: 140px;
  object-fit: contain;
}

.arrow-container{
  padding-top: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

}

.cta{
  
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  display: inline-flex;
}

.arrow {
  padding-left: 10px;
  max-width: 30px;
  max-height: 20px;
}

.fix{
  display: flex;
  align-items: center;
}



@media ${device.lg} {

  .arrow-container{
    padding-top: 35px;
  }
}

`

const Hero = ({ banner_images, header_cta }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection className="position-relative">
        <EarCTA>

          <Container className='cta-container'>
            <img className='hero-img' src={earLogoLight} />
            <div className='arrow-container w-100'>
              <div>
                <PaintDrip to="/contact" className='fix hover'>
                  <Text color="white" className='cta'>
                    {header_cta.text}
                  </Text>
                  <img src={arrowGlyph} className="arrow" height="15px" />

                </PaintDrip>

              </div>
            </div>
          </Container>

        </EarCTA>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          swipeable={false}
          draggable={false}
          autoPlaySpeed={3000}
          keyBoardControl={false}
          className='image-carousel'
          arrows={false}
        >
          {banner_images.map((item, index) => {
            return (
              <div>
                <Img fluid={item.image.localFile.childImageSharp.fluid} key={index} critical/> 
              </div>

            )
          })}
                        
        </Carousel>
    </StyledSection>
    </>
  )
}

export default Hero

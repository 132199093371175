import React from 'react'
import { Box, Title, Text } from '../Core'
import styled from 'styled-components'
import { device } from '../../utils'
import { Link } from 'gatsby'
var slugify = require('slugify')

const StyledBox = styled(Box)`
  img {
    object-fit: cover;
    width: 100%;
    height: 400px;
  }

  .cut-height img {
    height: 400px !important;
  }

  @media ${device.sm} {
    padding-bottom: unset;
    .unset {
      width: 100vw;
      max-width: unset;
      margin-left: -20px;
    }

    img {
      height: 275px;
    }
    .cut-height img {
      height: 275px !important;
    }

    .address {
      text-transform: uppercase;
      font-size: 20px;
      line-height: 25px;
      font-weight: 200;
    }
    .type {
      text-transform: uppercase;
      font-size: 15px;
      line-height: 15px;
      font-weight: 200;
      letter-spacing: 0.2px;
    }

    .swipe {
      display: block;
    }
  }

  @media ${device.md} {
    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
    }

    .cut-height img {
      height: 200px !important;
    }
  }

  @media ${device.lg} {
    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
    }

    .cut-height img {
      height: 400px !important;
    }
  }

  .type {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    font-weight: 200;
  }

  .address {
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
    font-weight: 200;
  }

  .swipe {
    display: none;
  }


`

const Property = ({
  price,
  address,
  image,
  bedroom,
  bathroom,
  car,
  unset,
  cutheight,
  streetNo,
  street,
  suburb,
  postcode
}) => {
  return (
    <>
      <StyledBox className='hover'>
        <Link to={`/rentals/${slugify(`${streetNo}-${street}-${suburb}-${postcode}`).toLowerCase()}`} className={`${cutheight && 'cut-height'}`} data-aos="zoom-in">
          <img src={image} className={`img-fluid ${unset && 'unset'} lazy`}></img>
          <Text className="address pt-3" color="#707070" variant="medium">
            {address}
          </Text>
          <Text className="type pt-3" color="#7295A7" variant="small">
            {bedroom} Bedroom | {bathroom} Bathroom | {car} Car
          </Text>

          <Text className="type" color="#7295A7" variant="small">
            ${price} Per Week
          </Text>
        </Link>
      </StyledBox>
    </>
  )
}

export default Property

import React from 'react'
import styled from 'styled-components'

import { Section, Title, Text } from '../components/Core'
import earBg from '../assets/ear/sydney-opera.jpg'
import { Container, Row, Col } from 'react-bootstrap'
import Line from '../components/Line'
import { device } from '../utils'
import { graphql } from "gatsby"
import Img from "gatsby-image"

const StyledSection = styled(Section)`
  height: 75vh;
  min-width: 100vw;
  background-position: center;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;

  .title {
    font-size: 40px;
    line-height: 50px;
    font-family: 'Vijaya';
    color: black;
  }

  .text {
    color: black;
    padding-top: 20px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
  }

  .middle {
    background-color: rgba(255, 255, 255, 0.8);
    max-width: 400px;
    padding-left: 50px;
    padding-right: 50px;
    height: 75vh;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${device.sm} {
    height: 65vh;
    .middle {
      max-width: 300px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media ${device.md} {
    min-height: 750px;

    height: 65vh;
    .middle {
      min-height: 750px;

      max-width: 300px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media ${device.lg} {
    height: 75vh;
  }

  .middle-container {
    display: flex;
    justify-content: center;
  }
`

const HeroCenter = ({slice}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection hero className="position-relative">
        <Img fluid={slice.primary.image.localFile.childImageSharp.fluid} css={{position: "absolute !important", width: "100%", height: "100%", zIndex: "-1"}}/>
        <Container className="middle-container">
          <div className="middle" data-aos="zoom-in">
            <Line />
            <Text className="text">{slice.primary.subline.text}</Text>

            <Title className="title">{slice.primary.title.text}</Title>
            <Text className="text">
              {slice.primary.text.text}
            </Text>
            <Line />
          </div>
        </Container>
      </StyledSection>
    </>
  )
}

export default HeroCenter

export const query = graphql`
  fragment PageDataBodyHeroCenterText on PrismicPageDataBodyHeroCenterText {
    primary {
      title {
        text
      }
      text {
        text
      }
      subline {
        text
      }
      image {
        localFile {
          childImageSharp {
            fluid {
              base64
              srcWebp
              srcSetWebp
              originalImg
              originalName
            }
          }
        }
      }
    }
  }
  
  
  `


import * as React from 'react'

import RichText from '../slices/RichText'
import Rentals from '../slices/Rentals'
import Blogs from '../slices/Blogs'
import Testimonials from '../slices/Testimonials'
import OurServices from '../slices/HomeOurServices'
import About from '../slices/HomeAbout'
import AboutUs from '../slices/AboutUs'
import Team from '../slices/Team'
import ServicesGlyph from '../slices/Glyph'

import HeroCenter from '../slices/HeroCenter'
import HeroCopy from '../slices/HeroCopy'


import Services from '../slices/Services'

export const SliceZone = ({ sliceZone }) => {
  const sliceComponents = {
    rich_text_with_cta: RichText,
    rental_properties: Rentals,
    rich_text_with_cta___banner_image: RichText,
    our_services: OurServices, 
    rich_text_basic: About, 
    testimonials_: Testimonials,
    recent_blogs: Blogs,
    hero_center_text:HeroCenter,
    about_us_text_block: AboutUs,
    meet_the_team: Team,
    hero_right_text: HeroCopy,
    what_we_do: ServicesGlyph,
    our_services_page: Services,
  }

  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return <h1>{slice.slice_type}</h1>
  })

  return <div>{sliceZoneContent}</div>
}

import React, { useState } from 'react'
import styled from 'styled-components'

import { Section, Text, Box } from '../components/Core'
import { Row, Col, Container } from 'react-bootstrap'

import team1 from '../assets/ear/team_1.png'

import { device } from '../utils'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useStaticQuery, graphql } from "gatsby"


const StyledSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .kind-words {
    font-family: 'Vijaya';

    font-size: 40px;
    line-height: 50px;
    padding-bottom: 20px;
    font-weight: 200;
    text-transform: uppercase;
  }

  .mobile-fix {
    display: none;
  }

  .swipe {
    display: block;
    font-weight: 200;
    font-size: 15px;
    line-height: 25px;
    padding-bottom: 25px;
  }

  @media ${device.sm} {
    padding-top: 30px;
    padding-bottom: 15px;

    .mobile-fix {
      display: flex;
    }

    .desktop-fix {
      display: none;
    }
    .kind-words {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .react-multi-carousel-list {
    padding-left: unset;
    padding-right: unset;
  }

  @media ${device.md} {
    .mobile-fix {
      display: flex;
    }

    .desktop-fix {
      display: none;
    }
  }

  @media ${device.lg} {
    .mobile-fix {
      display: none;
    }

    .desktop-fix {
      display: flex;
    }

    .swipe {
      display: none;
    }
  }
`

const StyledBox = styled(Box)`
  padding: 30px;
  margin: 20px;

  .title {
    
    padding-top: 10px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
    letter-spacing: 0.25px;
  }

  .name {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.25px;

    font-weight: 200;
    text-transform: uppercase;
    padding-top: 20px;
  }
  svg {
    margin-top: 10px;
  }

  @media ${device.sm} {
    margin: 0px;
    padding: 30px;
    .name {
      font-size: 15px;
      line-height: 20px;
    }
    .title {
      font-size: 20px;
      line-height: 25px;
    }
  }
`

const StyledCol = styled(Col)`
  svg {
    transition-duration: 0.5s;
    transition-property: transform;
  }
  .active svg {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .t-light {
    font-weight: 200;
    letter-spacing: 0px;
  }
`

const CrossButton = styled.div`
  :hover {
    cursor: pointer;
  }
`

const Testimonial = ({ title, name, copy, className, image={team1} }) => {
  const [active, setActive] = useState(false)

  const handleSwitch = () => {
    setActive(!active)
  }

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledCol className={className}>
        <StyledBox className="position-relative">
          <img src={image} className="img-fluid"></img>
          <Text color="#7295A7" className="name">
            {name}
          </Text>

          <Text color="#707070" className="title">
            {title}
          </Text>
          <CrossButton
            onClick={() => handleSwitch()}
            className={`${active ? 'active' : ''}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.885"
              height="11.885"
              viewBox="0 0 11.885 11.885"
            >
              <path
                id="Path_16"
                data-name="Path 16"
                d="M18,7.5V18.385"
                transform="translate(-12.058 -7)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_17"
                data-name="Path 17"
                d="M7.5,18H18.385"
                transform="translate(-7 -12.058)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </svg>
          </CrossButton>
          <Text
            color="#707070"
            className="title t-light"
            css={{ display: active ? 'block' : 'none' }}
          >
            {copy}
          </Text>
        </StyledBox>
      </StyledCol>
    </>
  )
}

const Team = ({slice}) => {
  const [count, setCount] = useState(1)

  const members = useStaticQuery(graphql`
  
  query TeamMembers {
    allPrismicTeamMembers {
      edges {
        node {
          id
          data {
            member {
              title {
                text
              }
              name {
                text
              }
              image {
                url
              }
              copy {
                text
              }
            }
          }
        }
      }
    }
  }  

  `)

  const member_map = members.allPrismicTeamMembers.edges[0].node.data.member


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  const responsiveTwo = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection bg="#F1F3F4" className="position-relative">
        <Container data-aos="zoom-in">
          <Text className="kind-words" color="#707070">
            {slice.primary.title.text}
          </Text>
          <Row>
            <Carousel
              responsive={responsive}
              infinite={false}
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              className="mobile-fix"
              afterChange={(nextSlide, { currentSlide, onMove }) => {
                setCount(currentSlide + 1)
              }}
            >
              {member_map.map((item, index) => {
                  return(
                    <Testimonial
                  name={item.name.text}
                  title={item.title.text}
                  copy={item.copy.text}
                  image={item.image.url}
                />
                );
                  
              })}


            </Carousel>
            <Carousel
              responsive={responsiveTwo}
              infinite={false}
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              className="desktop-fix"
            >
              {member_map.map((item, index) => {
                                if (index > 0){

                return  <Testimonial
                name={item.name.text}
                title={item.title.text}
                copy={item.copy.text}
                image={item.image.url}

              />;
                                }
              })}

            </Carousel>
          </Row>
          <Row className="">
            <Text className="swipe" color="black">
              {count}/{member_map.length} | Swipe for next
            </Text>
          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default Team

export const query = graphql`
  fragment PageDataBodyMeetTheTeam on PrismicPageDataBodyMeetTheTeam {
    primary {
      title {
        text
      }
      
    }
  }

  `

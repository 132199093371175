import React from 'react'
import styled from 'styled-components'
import { device } from '../utils'

import { Section, Text } from '../components/Core'

import Line from '../components/Line'
import { Container } from 'react-bootstrap'

import arrowGlyph from '../assets/ear/glyph/arrow.svg'
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

const StyledSection = styled(Section)`
  font-family: 'Vijaya';
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  height: 40vh;

  @media ${device.lg} {
    min-height: 500px;
  }
  .cta {
    font-family: 'Poppins';
    text-transform: uppercase;
    display: inline-block;
    font-weight: 800;
    line-height: 25px;
    padding: 10px 30px 10px 30px;
    font-size: 20px;
    font-weight: 200;
    display: inline-block;
    white-space: pre;
  }

  .cta-unset {
    padding: 10px 0px 10px 0px;
  }

  .bg-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    opacity: 0.6;
  }

  .opacity {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 2;
  }

  .content {
    z-index: 2;
  }
  .t-light {
    font-weight: 200;
  }

  .t-bold {
    font-weight: bold;
  }

  .arrow {
    padding-left: 5px;
    height: 15px;
    position: unset;
  }

  .cta-container {
    margin-top: 15px;
    display: inline-flex;
    align-items: center;
  }
  .subline {
    margin-top: 15px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 200;
    letter-spacing: 0.25px;

    font-family: 'Poppins';
  }

  a{
    display: flex;
    align-items: center;
  }
  `

const RichText = ({
  slice,
  cta,
  copy,
  opacity = null,
  subCopy = null,
  arrow = false,
  bgImg = null,
  lines = false,
  bg = 'light',
  textColor = 'black',
  bold = null,
}) => {

  if (typeof slice.primary.text != 'undefined'){
    var copy = slice.primary.text.text
  }else{
    var copy = "";
  }

  if (typeof slice.primary.cta != 'undefined'){
    var cta = slice.primary.cta.text
  }else{
    var cta = "";
  }

  if (typeof slice.primary.title1 != 'undefined'){
    var subCopy = slice.primary.title1.text
  }else{
    var subCopy = "";
    lines = true

  }

  if (typeof slice.primary.image != 'undefined'){
    var bgImg = true;
  }else{
    var bgImg = false;
  }




  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection
        className="position-relative"
        bg={bg}
        py={['0px', null, '0px', '15px']}
      >
        {lines && <Line color="#707070" />}

        {bgImg && <Img fluid={slice.primary.image.localFile.childImageSharp.fluid} css={{position: "absolute !important", width: "100%", height: "100%", zIndex: "1"}}/>}

        {bgImg && (
          <div className="opacity" style={{ backgroundColor: "white" }} />
        )}

        <Container className="content" data-aos="zoom-in">
          {subCopy && (
            <Text
              variant="small"
              color={textColor}
              className="mt-3 t-light subline"
            >
              FREEBIE ALERT!
            </Text>
          )}

          <Text variant="lg" color={textColor}>
            {copy}
          </Text>
          {subCopy && (
            <Text
              variant="small"
              color={textColor}
              className="mt-3 t-light subline"
            >
              {subCopy}
            </Text>
          )}
          <div className="cta-container">
            <Link to="/contact" className='hover'>
            <Text
              variant="small"
              color={textColor}
              className={`cta ${arrow && 'cta-unset'} ${bold && 't-bold'}`}
            >
              {cta}
            </Text>
            {arrow && <img src={arrowGlyph} className="arrow" />}
            </Link>
          </div>
        </Container>
        {lines && <Line color="#707070" />}
      </StyledSection>
    </>
  )
}

export default RichText

export const query = graphql`
  fragment HomepageDataBodyRichTextWithCta on PrismicHomepageDataBodyRichTextWithCta {
    primary {
      text {
        text
      }
      cta {
        text
      }
    }
  }

  fragment PageDataBodyRichTextWithCta on PrismicPageDataBodyRichTextWithCta {
    primary {
      text {
        text
      }
      cta {
        text
      }
    }
  }



  fragment HomepageDataBodyRichTextWithCtaBannerImage on PrismicHomepageDataBodyRichTextWithCtaBannerImage {
    primary {
      cta {
        text
      }
      title1 {
        text
      }
      text {
        text
      }
      image {
        localFile {
          childImageSharp {
            fluid {
              base64
              srcWebp
              srcSetWebp
              originalImg
              originalName
            }
          }
        }
      }
    }
  }


  `

  
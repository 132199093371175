import React from 'react'
import styled from 'styled-components'

import { Section, Text, Title, Box } from '../components/Core'
import { Row, Col, Container } from 'react-bootstrap'

import lina from '../assets/ear/lina.jpg'
import { device } from '../utils'
import { graphql } from "gatsby"
import Img from "gatsby-image"

const StyledSection = styled(Section)`
  text-align: center;

  .title {
    font-family: 'Vijaya';

    font-weight: 200;
    font-size: 40px;
    line-height: 50px;
    text-transform: uppercase;
  }

  img {
    padding-top: 50px;
    padding-bottom: 50px;
  }


  .copy p:after {
    white-space: pre;
  }
  .copy p{
    min-height: 20px;
  }

  .copy {
    margin: 20px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
  }

  .name {
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
    color: #7295a7;
    text-transform: uppercase;
  }

  .job-title {
    text-transform: uppercase;
    padding-top: 10px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
  }


  @media ${device.sm} {
    .desktop {
      display: none;
    }

    .title {
      font-size: 30px;
      line-height: 35px;
    }

    .copy {
      font-size: 20px;
      line-height: 25px;
    }
  }

  @media ${device.md} {
    .desktop {
      display: none;
    }

    
  }

  @media ${device.lg} {
    .desktop {
      display: block;
    }

    .image-block {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }


  .image-item{
    width: 100%;
    aspect-ratio: 400/600;
    position: relative;
  }

  .image-item .gatsby-image-wrapper{
    width: 100%;
    height: 100%;
  }

`

const AboutUs = ({slice}) => {

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection bg="white" className="position-relative">
        <Container>
          <Row>
            <Col md={4} className="image-block desktop">
              <div>
                <div className='image-item'>
                <Img fluid={slice.primary.image.localFile.childImageSharp.fluid}/>
                </div>
                <Text className="name">Lina Davies</Text>
                <Text className="job-title">
                  Founder and director of East Avenue Realty
                </Text>
              </div>
            </Col>
            <Col md={12} lg={8} css={{zIndex: "5"}}>
              <Title className="title">{slice.primary.title.text}</Title>
              <div className="copy" dangerouslySetInnerHTML={{ __html: slice.primary.text.html }}>
              </div>
            </Col>
          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default AboutUs

export const query = graphql`
  fragment PageDataBodyAboutUsTextBlock on PrismicPageDataBodyAboutUsTextBlock {
    primary {
      title {
        text
      }
      text {
        html
      }
      image {
        localFile {
          childImageSharp {
            fluid {
              base64
              srcWebp
              srcSetWebp
              originalImg
              originalName
            }
          }
        }
      }
    }
  }

  `

import React, { useState } from 'react'
import styled from 'styled-components'

import { Section, Title, Text } from '../components/Core'
import earBg from '../assets/ear/living_room.jpg'
import { Container, Row, Col } from 'react-bootstrap'
import { device } from '../utils'
import { Link } from "gatsby"
import { graphql } from 'gatsby'


const StyledSection = styled(Section)`
  min-width: 100vw;
  background-image: url(${earBg});
  background-position: center;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  min-height: 800px;


  .title {
    font-family: 'Vijaya';

    font-size: 45px;
    line-height: 55px;
    font-weight: 100;
    text-transform: uppercase;
    color: black;
  }

  .text {
    padding-top: 20px;
    font-size: 25px;
    line-height: 30px;
    font-weight: 200;
    letter-spacing: 0.25px;
    color: black;
    text-transform: uppercase;
    display: inline-block;
  }
  
  .col-fix{
    display: flex;
    flex-direction: column;
  }




  .learn-more {
    font-size: 20px;
    font-weight: 200;
  }

  @media ${device.sm} {
    min-height: 80vh;
    align-items: flex-start;
    .title {
      font-size: 30px;
      line-height: 40px;
    }
    .text {
      font-size: 25px;
      line-height: 35px;
    }

    .learn-more {
      font-size: 20px;
    }
  }

`

const OurServices = ({slice}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection className="position-relative">
        <Container>
          <Row>
            <Col lg={8}></Col>

            <Col lg={4} className='col-fix' data-aos="fade-left">
              <Title className="title">{slice.primary.services_title.text}</Title>
              {slice.items.map(item => {
                  return(
                    <Text className="text">{item.service_name.text}</Text>
                  ) 
                })}

              <Link to="/services" className='hover'>
              <Text className="text learn-more">{slice.primary.cta.text}</Text>

              </Link>
            </Col>
          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default OurServices


export const query = graphql`
  fragment HomepageDataBodyOurServices on PrismicHomepageDataBodyOurServices {
    primary {
      cta {
        text
      }
      services_title {
        text
      }
    }
    items {
      service_name {
        text
      }
    }
  }`

import React, { useState } from 'react'
import styled from 'styled-components'

import { Section, Text, Box } from '../components/Core'
import { Row, Col, Container } from 'react-bootstrap'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { device } from '../utils'

import tenet from '../assets/ear/services/house.svg'
import { Link } from "gatsby"
import { graphql } from "gatsby";

import Img from 'gatsby-image'


const StyledSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .kind-words {
    font-size: 50px;
    line-height: 60px;
    padding-bottom: 20px;
    font-weight: 200;
    text-transform: uppercase;
  }

  .learn-more {
    display: inline-block;
    margin-top: 50px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;

    font-size: 25px;
    line-height: 30px;
    font-weight: 200;
    text-transform: uppercase;
    border: 1px solid #707070;
  }

  .mobile {
    display: none;
  }

  @media ${device.sm} {
    .swipe {
      padding-top: 30px;

      display: block;
      font-weight: 200;
      font-size: 15px;
      line-height: 25px;
      padding-bottom: 15px;
    }

    .mobile {
      display: flex;
    }
    .desktop {
      display: none;
    }

    .kind-words {
      font-size: 30px;
      line-height: 35px;
    }

    .learn-more {
      margin-top: 0px;

      font-size: 20px;
      line-height: 25px;
    }
  }

  .react-multi-carousel-list {
    padding-left: unset;
    padding-right: unset;
  }
`

const StyledBox = styled(Box)`
  .name {
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    font-weight: 200;
  }

  @media ${device.sm} {
    .name {
      font-size: 20px;
      line-height: 25px;
    }
  }
`

const Service = ({ number, name, image }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Col md={3}>
        <img className="w-50" src={image.url} /> 
        <StyledBox bg="white" className="position-relative">
          <Text color="#707070" className="name">
            {name}
          </Text>
        </StyledBox>
      </Col>
    </>
  )
}

const ServicesGlyph = ({slice}) => {
  const [count, setCount] = useState(1)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection bg="white" className="position-relative">
        <Container>
          <Text className="kind-words" color="#707070">
            {slice.primary.title.text}
          </Text>
          <Row className="mobile">
            <Carousel
              responsive={responsive}
              infinite={false}
              removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
              afterChange={(nextSlide, { currentSlide, onMove }) => {
                setCount(currentSlide + 1)
              }}
            >
                    {slice.items.map((item, index) => {
                      return <Service name={item.title.text} number={`0${index}.`} image={item.image} />;
                    })}

              </Carousel>
          </Row>
          <Row className="desktop">
          {slice.items.map((item, index) => {
                      return <Service name={item.title.text} number={`0${index}.`} image={item.image} />;
                    })}


          </Row>
          <Row className="mobile">
            <Text className="swipe" color="black">
              {count}/{slice.items.length} | Swipe for next
            </Text>
          </Row>
          <div className='hover d-inline-block'>
          <Link to='/contact'>
          <Text className="learn-more" color="#707070">
            Contact
          </Text>

          </Link>

          </div>
        </Container>
      </StyledSection>
    </>
  )
}

export default ServicesGlyph


export const query = graphql`
  fragment PageDataBodyWhatWeDo on PrismicPageDataBodyWhatWeDo {
    primary {
      title {
        text
      }
    }
    items {
      title {
        text
      }
      image {
        url
      }
    }

  }
  
  
  `


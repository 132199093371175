import React from 'react'
import styled from 'styled-components'

import { Section, Title, Text } from '../components/Core'
import earBg from '../assets/ear/living_room.jpg'
import { Container, Row, Col } from 'react-bootstrap'
import { device } from '../utils'
import { graphql } from "gatsby";
import Img from "gatsby-image"

const StyledSection = styled(Section)`
  height: 75vh;
  min-width: 100vw;
  background-position: center;
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;

  .title {
    font-size: 35px;
    line-height: 45px;
    font-family: 'Vijaya';
    color: black;
  }

  .text {
    color: black;
    padding-top: 20px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
  }

  @media ${device.md} {

    min-height: 750px;

    .text {
      max-width: 50vw;
    }

    .title {
      max-width: 50vw;
    }

    .tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const HeroCopy = ({slice}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection hero className="position-relative">
      <Img fluid={slice.primary.image.localFile.childImageSharp.fluid} css={{position: "absolute !important", width: "100%", height: "100%", zIndex: "-1"}}/>
        <Container>
          <Row>
            <Col lg={8}></Col>

            <Col lg={4} className="tablet" data-aos="fade-left">
              <Title className="title">
                {slice.primary.title.text}
              </Title>
              <Text className="text">
                {slice.primary.text.text}
              </Text>
            </Col>
          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default HeroCopy

export const query = graphql`
  fragment PageDataBodyHeroRightText on PrismicPageDataBodyHeroRightText {
    primary {
      title {
        text
      }
      text {
        text
      }
      image {
        localFile {
          childImageSharp {
            fluid {
              base64
              srcWebp
              srcSetWebp
              originalImg
              originalName
            }
          }
        }
      }
    }

  }`
